
import { reactive, ref, Ref, watch } from 'vue'
import { useStore } from 'vuex';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useAlert from "../../../composables/Alert";
import { excelRelatorioAnual} from '@/services/RelatorioExcel'
import moment from "moment";
import { generateExcelBlob, dowloadExcel } from "@/composables/Excel";
import ModalSendEmail from './components/ModalSendEmail.vue'
import { ConcessionariaModel } from '@/models/ConcessionariaModel';

export default {
  components: {
    ElConfigProvider,
    ModalSendEmail
  },
  setup() {
    const optionsConcessionaria = ref<ConcessionariaModel[]>([])
    const codConcessionariaSelected = ref()
    const currentDate = new Date();
    const formattedDate = currentDate.toString();
    const existReport = ref(true)
    const loading = reactive({
      concessionarias: true,
      generateExcel: false,
      buttonGenerateReport: false,
      disableInput: false
    })
    const filter = reactive({
      ano: formattedDate,
      codConcessionariaSelected: '',
    })
    const store = useStore();
    const concesionariasList = ref<ConcessionariaModel[]>([])
    const startYear = 2018;
    const endYear = currentDate.getFullYear();
    const { showTimeAlert } = useAlert();
    const blob: Ref<Blob | null> = ref(null)
    const nomeConcessionariaSelect = ref()

    watch(() => filter, () => {
      existReport.value = true
      loading.buttonGenerateReport = false
    }, { deep: true })

    store.watch(() => store.getters.concessionariasList, () => {
      optionsConcessionaria.value = store.getters.concessionariasList
      loading.concessionarias = false
    }, { deep: true });

    const generateReport = async (): Promise<void> => {

      loading.buttonGenerateReport = true
      loading.generateExcel = true
      loading.disableInput = true

      const { codConcessionariaSelected } = filter;

      try {
        const response = await excelRelatorioAnual({
          "codConcessionaria": Number(codConcessionariaSelected),
          "ano": Number(moment(filter.ano).format('YYYY'))
        })

        blob.value = generateExcelBlob(response)
        existReport.value = false
        loading.generateExcel = false
        loading.buttonGenerateReport = true
        loading.disableInput = false

        showTimeAlert("Relatorio gerado com sucesso");

        nomeConcessionariaSelect.value = optionsConcessionaria.value?.find(concessionaria => concessionaria.codConcessionaria == codConcessionariaSelected)?.nomeFantasia

      } catch (e) {
        showTimeAlert("Ocorreu um problema. Por favor, tente novamente.");
        blob.value = null
        loading.buttonGenerateReport = true
        loading.disableInput = false
      }
    }

    const downloadExcel = (): void => {
      if (blob.value) {
        const { codConcessionariaSelected } = filter

        let fileName;
        const nameConcessionaria = optionsConcessionaria.value?.find(({
          codConcessionaria
        }) => codConcessionaria == codConcessionariaSelected)?.nomeFantasia

        if (nameConcessionaria) {
          const yearFormat = moment(filter.ano).format('YYYY')
          fileName = `relatorio_excel_${nameConcessionaria}_${yearFormat}`
        } else {
          fileName = 'relatorio_excel'
        }

        dowloadExcel(blob.value, fileName)
      }
    }

    function disabledDate(time) {
      const year = new Date(time).getFullYear();
      return year < startYear || year > endYear;
    }

    return {
      optionsConcessionaria,
      codConcessionariaSelected,
      ptBr,
      filter,
      endYear,
      disabledDate,
      generateReport,
      existReport,
      downloadExcel,
      loading,
      blob,
      nomeConcessionariaSelect,
      concesionariasList
    }
  }
}
