<template>
  <div>
    <div class="modal fade" id="ModalSendEmailRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enviar Email</h5>
          </div>
          <div class="modal-body">
            <el-form
                ref="ruleFormRef"
                :model="form"
                :rules="rules"
                status-icon
                class="demo-ruleForm"
                @submit.native.prevent
            >
              <div class="col-12">
                <label class="mb-0">Para:</label>
                <el-form-item prop="email">
                  <el-input
                      size="small"
                      type="text"
                      v-model="form.email"
                      placeholder="exemplo@email.coml.br"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <label class="mb-0">Assunto:</label>
                <el-form-item prop="subject">
                  <el-input
                      size="small"
                      type="text"
                      v-model="form.subject"
                      placeholder="Digite o assunto"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <label class="mb-0">Messagem:</label>
                <el-form-item prop="messagem">
                  <el-input
                      v-model="form.message"
                      :autosize="{ minRows: 6, maxRows: 6 }"
                      type="textarea"
                      placeholder="Digite sua messagem"
                  />
                </el-form-item>
              </div>
              <el-form-item>
                <div class="d-flex justify-content-end px-0 mt-5">
                  <button
                      :disabled="loadingSendEmail"
                      type="button"
                      class="btn btn-sm background_btn_1 text-white"
                      :class="{ disabled: loadingButton }"
                      :data-kt-indicator="loadingButton ? 'on' : 'off'"
                      id="kt_button_1"
                      @click="submitForm(ruleFormRef)"
                  >
                    <span v-if="!loadingSendEmail" class="indicator-label">
                             <i class="fa fa-envelope text-white"></i>
                            Enviar
                        </span>
                    <span v-else >
                          Enviando E-mail...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                  </button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref,reactive , watch,onMounted} from "vue";
import {mecanicoAdd} from "@/services/CrudService";
import {sendEmailExcel} from "@/services/RelatorioExcel";
import {updateServico} from "@/services/Estoque";
import {Modal} from "bootstrap";
import useAlert from "@/composables/Alert";
import store from "@/store";
import {useStore} from "vuex";
export default ({
  name: "Modal-send-email",
  props:{
    blob : Blob,
    nameConcessionaria: String
  },
  setup(prop) {
    const ruleFormRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      email: '',
      setFrom: '',
      subject: '',
      message: '',
      fileName: ''
    })
    const blob = ref()
    const auxModal = ref()
    const {showTimeAlert} = useAlert();
    const store = useStore()
    const loadingSendEmail = ref(false)

    onMounted(()=> {
      const auxElementModal = document.getElementById("ModalSendEmailRelatorio");
      auxModal.value = new Modal(auxElementModal);
    })

    watch(() => prop.blob, (newBlob) => {
      blob.value = newBlob
      form.email = ''
      form.messagem = ''
      form.setFrom = store.getters.usuarioLogado.nome
      form.fileName = prop.nameConcessionaria
    })

    const rules = ref({
      email: [
        { required: true, message: "Por favor, digite um email", trigger: "change" },
        { type: "email", message: "Por favor, digite um email v�lido", trigger: "change" },
      ],
      subject: [
        { required: true, message: "Por favor, digite um assunto", trigger: "change" },
      ],
    });

    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function() {
          const base64String = reader.result.split(',')[1]; // Remove o cabe�alho da URL de dados
          resolve(base64String);
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    }
    const handleSendEmail = async () => {

      const base64String = await blobToBase64(blob.value);

      const dataEmail = {
        email : form.email,
        subject : form.subject,
        message : form.message,
        nameFile: form.fileName,
        setFrom : form.setFrom,
        file : JSON.stringify(base64String)
      }
      loadingSendEmail.value = true
      const response = sendEmailExcel(dataEmail)
          .then(() => {
            auxModal.value.hide()
            showTimeAlert("Relatorio gerado com sucesso");
            form.email = ''
            form.message = ''
            form.subject = ''
            loadingSendEmail.value = false
          }).catch(() => {
            showTimeAlert("Relatorio gerado com sucesso", "error");
            loadingSendEmail.value = false
            form.email = ''
            form.message = ''
            form.subject = ''
          })
    }

    const submitForm = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          handleSendEmail()

        }
      })
    }

    return {
      ruleFormRef,
      rules,
      form,
      submitForm,
      loadingSendEmail
    }

  }

})
</script>