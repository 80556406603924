import ApiService from "./ApiService";

export const getEstoqueConcessionaria  = async (codConcessionaria) => {
    const { data } = await ApiService.get(`/estoque/${codConcessionaria}` , codConcessionaria);
    return data;
 };

 export const updateServico  = async (id, postData) => {
    const { data } = await ApiService.post(`/estoque/update/${id}` , postData);   
    return data;
 };

 export const atualizarEstoque  = async (codConcessionaria) => {
   const { data } = await ApiService.get(`estoque/atualizarEstoque/${codConcessionaria}` , codConcessionaria);   
   return data;
};

export const getDmsConcessionaria = async(codConcessionaria) => {
   const { data } = await ApiService.get(`estoque/dmsConcessionaria/${codConcessionaria}` , codConcessionaria);   
   return data;
}
export const estoquePdf = async(postData) => {
   const { data } = await ApiService.post(`pdf/estoque`,postData);   
   return data;
}

export default {getEstoqueConcessionaria,updateServico,atualizarEstoque,getDmsConcessionaria,estoquePdf}
 