import ApiService from "./ApiService";

 const excelRelatorioAnual = async (payLoad):  Promise<Blob> => {
    const { data } = await ApiService.postXlsx(`/vendas/relatorio-excel`, payLoad);
    return data;
};

 const sendEmailExcel = async (payload) => {
     const { data } = await ApiService.post(`/vendas/sendEmail`, payload);
     return data;
 }

export {excelRelatorioAnual, sendEmailExcel}